<!--常见问题-->
<template>
  <div class="container">
    <!-- <div class="type" v-for="(item, idx) in faqList" :key="idx">
      <div class="title">{{ item.name }}</div>
      <div class="content">
        <div
          class="item"
          v-for="faq in item.faqList"
          :key="faq.faqId"
          @click="viewDetail(faq.faqId)"
        >
          <div class="txt">{{ faq.problem }}</div>
          <div>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div> -->
    <!-- 手风琴 -->
    <van-collapse
      v-model="activeName"
      accordion
      class="type"
      v-for="(item, idx) in faqList"
      :key="idx"
      @change="change"
    >
      <div class="problem-title">{{ item.name }}</div>
      <van-collapse-item
        :is-link="false"
        :title="faq.problem"
        :name="faq.faqId"
        v-for="faq in item.faqList"
        :key="faq.faqId"
        >{{ faq.answer }}
        <div slot="right-icon">
          <van-icon v-if="faq.faqId === current" name="arrow-down" />
          <van-icon v-else name="arrow" />
        </div>
      </van-collapse-item>
    </van-collapse>
    <!-- 手风琴... -->
  </div>
</template>

<script>
import { getFaqList } from "@/api/api";

export default {
  data() {
    return {
      faqList: [],
      activeName: "",
      current: null,
    };
  },
  created() {
    getFaqList().then(({ data }) => {
      this.faqList = data;
      console.log("this.faqList结果===>", this.faqList);
    });
  },
  methods: {
    change(e) {
      console.log("结果===>", e);
      this.current = e;
    },
    viewDetail(id) {
      this.$router.push("/problem/detail?id=" + id);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100vh;
  background-color: #ffffff;

  .type {
    padding: 5px;
    background-color: #ffffff;
    border-radius: 4px;
  }

  .problem-title {
    font-weight: bold;
    font-size: 16px;
    margin-left: 16px;
    margin-top: 4px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }
  .question-icon {
    margin-left: 4px;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    padding: 20px 10px;
  }

  .item {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 10px;
    border-top: 2px solid rgb(247, 247, 249);
  }
  .item:active {
    opacity: 0.7;
  }

  /deep/ .van-collapse-item--border::after {
    // border-top: 0;
    border: none;
  }
  /deep/ .van-cell::after {
    border: none;
  }
  /deep/ .van-collapse-item__wrapper {
    padding: 0px 16px;
  }
  /deep/ .van-collapse-item__content {
    background: #f2f2f2;
    // padding: 0;
    border-radius: 5px;
  }
}
</style>
